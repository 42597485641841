import Bar from './Bar';
import { getAnalytics, logEvent } from 'firebase/analytics';
import Footer from './Footer';

function NotReady() {


  const analytics = getAnalytics();
  logEvent(analytics, 'play-button-clicked');
  return (
    <div className="not-ready">
      <Bar />
      <div className='not-ready-content'>
        <h3>Aplikacja nie jest jeszcze gotowa</h3>
        <p>Dziękujęmy za zainteresowanie aplikacją!</p>
        <br/>
        <p>Niestety aplikacja na Sklep Play nie jest jeszcze dostępna 😞</p>
        <p>W międzyczasie zapraszamy do zapoznania się z naszą stroną internetową lub aplikacją na urządzenia iOS.</p>
        <br/>
        <p>Pozdrawiamy,</p>
        <p className='blue-text'>Zespół eGodzimy</p>
        <br/><br/>
        <a href='https://egodziny.pl' className='button'>
          <span className="material-symbols-outlined">arrow_back</span>
          Powrót do strony głównej
        </a>
      </div>
      <Footer />
    </div>
  );
}

export default NotReady;
